<template>
    <common-modal ref="modal" :show="show"
        title="Cambiar dirección"
        @close="$emit('update:show', false)">
        <div v-if="ruc" class="row">
            <div class="col-sm-12">
                <label class="form-label">Dirección</label>
                <input-search
                    v-model="form"
                    :source="{ url: urlAddress, method: 'POST' }"
                    prop-label="direccioncliente"
                    prop-value="codubigeo"
                    :initLoad="true"
                />
                <div class="invalid-tooltip">Se requiere dirección</div>
            </div>
        </div>

        <template #footer>
            <button @click="$emit('update:show', false)" class="btn btn-default">
                <span>Cerrar</span>
            </button>
            <button @click="handleAccepted" type="button" class="btn btn-secondary-gradien">
                <i class="fa fa-check"></i>
                <span>Aceptar</span>
            </button>
        </template>
    </common-modal>
</template>

<script>
import { trim } from 'lodash';

export default {
    name: 'modal-address',
    data: () => ({
        form: null,
        /*form: {
            direccioncliente: '',
            codubigeo: null,
            codigolugar: null,
        }*/
    }),
    inject: ['baseUrl'],
    props: {
        show: {
            type: Boolean,
        },
        ruc: {
            type: String,
            required: false,
        }
    },
    computed: {
        urlAddress() {
            return `${this.baseUrl}/api/clients/${trim(this.ruc)}/address`
        }
    },
    methods: {
        handleAccepted() {
            if(this.form === null) {
                $.notify('Se requiere que seleccione una dirección', {
                    type: 'danger',
                    allow_dismiss: true,
                    delay: 2000,
                    timer: 300,
                    animate:{
                        enter:'animated fadeInDown',
                        exit:'animated fadeOutUp'
                    }
                });
                return;
            }
            this.$emit('accepted', this.form)
            this.$emit('update:show', false)
        }
    }
}
</script>

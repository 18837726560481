<template>
    <div class="container-fluid">
        <div class="row widget-grid">
            <div class="col-12">
                <div class="mb-3">
                    <div class="d-flex justify-content-between">
                        <button @click="isOpen = !isOpen" type="button" class="btn text-start px-2 d-flex align-items-center" style="width: 80%;" data-bs-toggle="collapse" data-bs-target="#search-box">
                            <i v-if="isOpen" class="fa fa-minus"></i>
                            <i v-else class="fa fa-plus"></i>
                            <span class="text-uppercase ms-2">Filtros de búsqueda</span>
                        </button>
                        <a :href="`${resource}/new`" class="btn btn-secondary-gradien">
                            <i class="fa fa-plus"></i>
                            <span>Nuevo</span>
                        </a>
                    </div>
                    <div class="mt-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3 col-lg-2 col-form-label text-sm-end mb-3">Rango de fechas</div>
                                    <div class="col-sm-9 col-lg-8 col-xl-6 mb-3">
                                        <div class="d-flex flex-row align-items-center gap-3">
                                            <div class="input-group">
                                                <span class="input-group-text">Desde</span>
                                                <input v-model="search.start_date" type="text" class="datepicker-here form-control digits" data-language="es">
                                            </div>
                                            <div class="input-group">
                                                <span class="input-group-text">Hasta</span>
                                                <input v-model="search.end_date" type="text" class="datepicker-here form-control digits" data-language="es">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row collapse" id="search-box">
                                    <div class="col-sm-2 col-form-label text-sm-end mb-3">Cliente</div>
                                    <div class="col-sm-10 col-md-9 col-xl-6 mb-3">
                                        <input-search v-model="search.ruc"
                                            prop-value="ruccliente" prop-label="nombrecliente"
                                            :source="{ url: `${baseUrl}/api/clients`, method: 'POST' }"
                                            type="text" placeholder="Razón social o RUC de cliente">
                                            <template v-slot:selected="{ selected }">
                                                <span class="me-2">{{ selected.ruccliente }}</span>
                                                <span>{{ selected.nombrecliente }}</span>
                                            </template>

                                            <div slot-scope="{ item, select }" @click="select(item)" class="px-4 py-2 w-100 d-flex flex-row" style="cursor: pointer;">
                                                <span class="me-2">{{ item.ruccliente }}</span>
                                                <span>{{ item.nombrecliente }}</span>
                                            </div>
                                        </input-search>
                                    </div>
                                    <div class="col-sm-2 col-md-2 col-xl-1 col-form-label text-sm-end mb-3">Vendedor</div>
                                    <div class="col-sm-3 mb-3 col-xl-2">
                                        <input-search
                                            v-model="search.seller"
                                            prop-value="codigocampo"
                                            prop-label="nombrecampo"
                                            :minLength="0"
                                            :source="{ url: `${baseUrl}/api/tables?a=3&e=0`, method: 'POST' }" type="text"
                                            :initLoad="true"
                                        />
                                        <!--<select-async
                                            required
                                            v-model="search.seller"
                                            :url="`${baseUrl}/api/tables?a=3&e=0`"
                                            prop-label="nombrecampo"
                                            prop-value="codigocampo"
                                        ></select-async>-->
                                    </div>
                                    <div class="col-sm-3 col-xl-2 mb-3 col-form-label text-sm-end">Orden de compra</div>
                                    <div class="col-sm-3 mb-3">
                                        <input v-model="search.purchase_order" type="text" class="form-control">
                                    </div>
                                    <div class="col-sm-2 col-form-label text-sm-end">N° de pedido</div>
                                    <div class="col-sm-3 mb-3">
                                        <input v-model="search.number_order" type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col text-end">
                                        <button @click="asyncData" class="btn btn-info-gradien">
                                            <i class="fa fa-search"></i>
                                            <span>Buscar</span>
                                        </button>
                                        <a :href="exportUrl" class="btn btn-success-gradien">
                                            <i class="fa fa-file-excel-o"></i>
                                            <span>Exportar</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <dt-table
                            ref="table"
                            nro-cols="10"
                            :config="{
                                createdRow(row, data, dataIndex, cells) {
                                    if(data.casouno === '1') {
                                        $(row).addClass('row-green');
                                    }
                                    if(data.casouno === '2') {
                                        $(row).addClass('row-gray');
                                    }
                                }
                            }"
                        >
                            <template #columns>
                                <tr>
                                    <th class="text-uppercase" style="width: 30px !important;">-</th>
                                    <th class="text-uppercase w-8">N° Pedido</th>
                                    <th class="text-uppercase w-6">Fecha Pedido</th>
                                    <th class="text-uppercase w-28">Cliente</th>
                                    <th class="text-uppercase w-4">Ord. compra</th>
                                    <th class="text-uppercase">Estado</th>
                                    <th class="text-uppercase">Vendedor</th>
                                    <th class="text-uppercase">Total</th>
                                </tr>
                            </template>

                            <template slot-scope="{ row, index }">
                                <tr
                                    :key="index" :data-color="`bg-${row.casouno}`"
                                    class="row-selected"
                                    :class="{'active': selectedIndex === index}"
                                    @click="selectedIndex = index"
                                >
                                    <td>
                                        <dropdown>
                                            <template v-slot:label>
                                                <i class="fa fa-ellipsis-v"></i>
                                            </template>
                                            <template v-slot:default="slotProps">
                                                <ul class="list-group">
                                                    <li class="list-group-item">
                                                        <a class="d-flex" @click="slotProps.toggle()" :href="`${baseUrl}/orders/${row.idcabpedido}/view`">
                                                            <i class="icon-eye"></i>
                                                            <span class="ms-2">Ver</span>
                                                        </a>
                                                    </li>
                                                    <li class="list-group-item">
                                                        <a v-if="row.estadoedicion === '1'" @click="slotProps.toggle()" class="d-flex" :href="`${baseUrl}/orders/${row.idcabpedido}/edit`">
                                                            <i class="icon-pencil-alt"></i>
                                                            <span class="ms-2">Editar</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </template>
                                        </dropdown>
                                    </td>
                                    <td class="w-8">{{ row.numpedido }}</td>
                                    <td class="w-6">{{ row.fechaemisiontexto }}</td>
                                    <td class="w-28">
                                        <div class="d-flex flex-column">
                                            <small class="fw-bold">RUC:</small>
                                            <span>{{ row.ruccliente }}</span>
                                            <small class="fw-bold text-uppercase">Razón social:</small>
                                            <span>{{ row.nombrecliente }}</span>
                                        </div>
                                    </td>
                                    <td class="w-4">{{ row.num_ocom }}</td>
                                    <td>{{ row.estado }}</td>
                                    <td>{{ row.sigla_vendedor }}</td>
                                    <td class="text-end">{{ row.tipomone }} {{ row.imp_ttot }}</td>
                                </tr>
                            </template>
                        </dt-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'orders-index',
    data: () => ({
        isOpen: false,
        search: {
            start_date: null,
            end_date: null,
            ruc: null,
            seller: null,
            purchase_order: null,
            number_order: null,
        },
        selectedIndex: -1,
    }),
    inject: ['baseUrl'],
    props: {
        resource: {
            type: String,
            required: false,
        }
    },
    computed: {
        exportUrl() {
            return `${this.resource}/export?`
        }
    },
    methods: {
        asyncData() {
            this.$refs['table'].fetchData({
                url: `${this.resource}/records`,
                method: 'POST',
                data: this.search,
            })
        }
    },
    mounted() {
        this.search.end_date = moment().format('DD/MM/YYYY');
        this.search.start_date = moment().add(-2, 'days').format('DD/MM/YYYY');

        setTimeout(() => {
            this.asyncData()
        }, 1000)
    }
}
</script>

<style>
    tr[data-color="bg-1"] {
        background-color: rgba(203,232,162, .9) !important;
    }

    tr[data-color="bg-2"] {
        background-color: rgba(224,226,221, .9) !important;
    }
</style>

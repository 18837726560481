<template>
    <common-modal :show="show" alias="form"
        title="Stock de almacén" size="md"
        @close="$emit('update:show', false)">
        <div v-if="product" class="row">
            <div class="col-12 d-flex flex-column mb-3">
                <label class="form-label fw-bold">Descripción de producto</label>
                <input :value="product.des_prod" class="form-control" type="text" readonly />
            </div>
            <div class="col-6 col-lg-4 d-flex flex-column mb-3">
                <label class="form-label fw-bold">Código</label>
                <input :value="product.cdg_prod" class="form-control" type="text" readonly />
            </div>
            <div class="col-6 col-lg-4 d-flex flex-column mb-3">
                <label class="form-label fw-bold">Cód. Equivalente</label>
                <input :value="product.cdg_otro" class="form-control" type="text" readonly />
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>ALMACÉN</th>
                        <th>STOCK</th>
                        <th>UNIDAD MEDIDA</th>
                        <th>UBICACIÓN</th>
                        <th>COMENTARIO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in records" :key="index">
                        <td>{{ row.nombrealmacen }}</td>
                        <td class="text-end">{{ parseFloat(row.stockporalmacen).toFixed(3) }}</td>
                        <td>{{ row.medida }}</td>
                        <td>{{ row.ubicacion }}</td>
                        <td>{{ row.comentario }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </common-modal>
</template>

<script>
export default {
    name: "modal-stock",
    inject: ['baseUrl'],
    data: () => ({
        records: [],
    }),
    props: {
        show: {
            type: Boolean,
            required: false,
        },
        product: {
            type: Object,
            default: null,
        }
    },
    watch: {
        show: {
            handler(f) {
                if(f) {
                    this.httpAsync()
                }
            },
            deep: true
        }
    },
    methods: {
        httpAsync() {
            this.$http.post(`${this.baseUrl}/api/products/stock`, {
                cdg_prod: this.product.cdg_prod,
            }).then(({ data }) => {
                this.records = data
            })
        }
    }
}
</script>

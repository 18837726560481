/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Axios from 'axios';
import Lodash from 'lodash';
import Vue from 'vue';

require('./bootstrap');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

Vue.prototype.$http = Axios.create();
Vue.prototype.$lodash = Lodash;
Vue.prototype.$randomUUID = (prefix, len = 8) => {
    const letters = 'abcdefghijklmnopkrstuvwxyz';
    let text = `${prefix}`;
    for (let i = 0; i < len; i++) {
        text += letters[Math.floor(Math.random() * letters.length)];
    }
    return text;
}

Vue.prototype.$currencyFormat = (number) => {
    number = (number === NaN || number === undefined || number === null) ? 0 : number;
    number = parseFloat(number);
    return number.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

Vue.component('overlay-loader', require('@components/common/OverlayLoader.vue').default);
Vue.component('dt-table', require('@components/common/DataTable.vue').default);
Vue.component('input-search', require('@components/common/InputSearch.vue').default);
Vue.component('select-async', require('@components/common/SelectAsync.vue').default);
Vue.component('common-modal', require('@components/common/Modal.vue').default);
Vue.component('dropdown', require('@components/common/Dropdown.vue').default);
Vue.component('modal-address', require('@components/ModalAddress.vue').default);
Vue.component('modal-product', require('@components/ModalProduct.vue').default);
Vue.component('modal-stock', require('@components/ModalStock.vue').default);
Vue.component('table-products', require('@components/TableProducts.vue').default);
Vue.component('search-products', require('@components/SearchProducts.vue').default);

Vue.component('orders-index', require('@orders/orders/Index.vue').default)
Vue.component('orders-form', require('@orders/orders/Form.vue').default)
Vue.component('orders-consult-products', require('@orders/ConsultProducts.vue').default)
Vue.component('last-orders', require('@orders/orders/LastOrders.vue').default)
Vue.component('sites-form', require('@/sites/Form.vue').default)

Vue.component('tracking-index', require('@orders/tracking/Index.vue').default)
Vue.component('tracking-events', require('@orders/tracking/Events.vue').default)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

if(document.getElementById('app')) {
    const container = document.getElementById('app');
    const baseUrl = container.getAttribute('base-url');
    const loginUser = container.getAttribute('user');
    const wareHouse = container.getAttribute('ware-house');
    const userSession = container.getAttribute('user-session');

    const app = new Vue({
        el: '#app',
        provide: () => ({
            baseUrl,
            loginUser: JSON.parse(loginUser),
            wareHouse: JSON.parse(wareHouse),
            userSession: JSON.parse(userSession),
        }),
    });

    window['vueApp'] = app;
}

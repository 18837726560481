<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-lg-4 col-xl-3">
                <div class="input-group mb-3">
                    <span class="input-group-text">Búscar por</span>
                    <select v-model="search.column" class="form-control">
                        <option value="pro.cdg_pro">Código</option>
                        <option value="pro.cdg_otro">Código Equivalente</option>
                        <option value="pro.des_prod">Nombre</option>
                        <option value="pro.cdg_bar">Código de Barra</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-12 col-lg-8 col-xl-6">
                <div class="input-group mb-3">
                    <input v-model="search.value" @keypress.enter="handleSearch" placeholder="Términos de búsqueda" type="text" class="form-control">
                    <button @click="handleSearch" type="button" class="btn btn-info">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
        <dt-table ref="table">
            <template #columns>
                <tr>
                    <th>-</th>
                    <th class="text-uppercase">Código</th>
                    <th class="text-uppercase">Descripción de Producto</th>
                    <th class="text-uppercase">U.M.</th>
                    <th v-if="wareHouse.estadomostrarmarca == '1'" class="text-uppercase">Marca</th>
                    <th class="text-uppercase">Stock ({{wareHouse.nombrealmacen}})</th>
                    <th v-if="wareHouse.estadoprecio1 == '1'" class="text-uppercase">{{ wareHouse.nombreprecio1 }}</th>
                    <th v-if="wareHouse.estadoprecio2 == '1'" class="text-uppercase">{{ wareHouse.nombreprecio2 }}</th>
                    <th v-if="wareHouse.estadoprecio3 == '1'" class="text-uppercase">{{ wareHouse.nombreprecio3 }}</th>
                    <th v-if="wareHouse.estadoprecio4 == '1'" class="text-uppercase">{{ wareHouse.nombreprecio4 }}</th>
                </tr>
            </template>

            <template v-slot:default="{ row, index }">
                <tr
                    :key="index" class="row-selected"
                    :class="{'active': selectedIndex === index}"
                    @click="clickRow({row, index}, $event)"
                    @dblclick="dblClickRow({row, index}, $event)"
                >
                    <td style="width: 30px !important;">
                        <div>
                            <a href="javascript: void(0);" data-bs-toggle="dropdown" class="btn btn-primary btn-square btn-sm btn-pill">
                                <i class="fa fa-ellipsis-v"></i>
                            </a>
                            <div class="dropdown-menu">
                                <button @click="handleStockWhareHouse(row, $event)" class="dropdown-item" type="button">
                                    <i class="icon-eye"></i>
                                    <span class="ms-2">Stock por almacén</span>
                                </button>
                                <button class="dropdown-item" type="button">
                                    <i class="icon-list"></i>
                                    <span class="ms-2">Historial de pedidos</span>
                                </button>
                            </div>
                        </div>
                    </td>
                    <td>{{ row.cdg_prod }}</td>
                    <td>{{ row.des_prod }}</td>
                    <td>{{ row.siglaum }}</td>
                    <td v-if="wareHouse.estadomostrarmarca == '1'">{{ row.marca }}</td>
                    <td class="text-end">{{ parseFloat(row.stock).toFixed(3) }}</td>
                    <td v-if="wareHouse.estadoprecio1 == '1'" class="text-end">{{ $currencyFormat(row.preciov1) }}</td>
                    <td v-if="wareHouse.estadoprecio2 == '1'" class="text-end">{{ $currencyFormat(row.preciov2) }}</td>
                    <td v-if="wareHouse.estadoprecio3 == '1'" class="text-end">{{ $currencyFormat(row.preciov3) }}</td>
                    <td v-if="wareHouse.estadoprecio4 == '1'" class="text-end">{{ $currencyFormat(row.preciov4) }}</td>
                </tr>
            </template>
        </dt-table>

        <modal-stock
            :show.sync="showModalStock"
            :product="selectedProduct"
        ></modal-stock>
    </div>
</template>

<script>
export default {
    name: 'table-products',
    data: () => ({
        search: {
            column: 'pro.des_prod',
            value: null,
        },
        selectedIndex: -1,
        showModalStock: false,
        selectedProduct: null,
    }),
    inject: ['baseUrl', 'wareHouse'],
    methods: {
        handleSearch() {
            this.$refs['table'].fetchData({
                url: `${this.baseUrl}/api/products`,
                method: 'POST',
                data: {
                    ...this.search,
                    limit: 15,
                }
            })
        },
        clickRow({ row, index}, evt) {
            this.selectedIndex = index
            this.$emit('selected', {
                row,
                index,
            }, evt)
        },
        dblClickRow({ row, index}, evt) {
            this.selectedIndex = index
            this.$emit('accepted', {
                row,
                index
            }, evt)
        },
        handleStockWhareHouse(row, evt) {
            this.showModalStock = true
            this.selectedProduct = row
        }
    }
}
</script>

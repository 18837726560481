<template>
    <div>
        <common-modal :show="show" alias="form"
            title="Información de producto" size="lg"
            @close="$emit('update:show', false)">
            <template>
                <div class="row">
                    <div class="col-sm-12 mb-3">
                        <div class="d-flex justify-content-between">
                            <label class="form-label required">Producto</label>
                            <div class="d-flex flex-row">
                                <button @click="showStock = true;" :disabled="form.item === null" type="button" class="btn btn-link btn-sm">
                                    <i class="fa fa-home" style="font-size: .9rem;"></i>
                                    <span>Stock por almacén</span>
                                </button>
                                <button @click="showAdvance = true;" type="button" class="btn btn-link btn-sm">
                                    <i class="fa fa-search" style="font-size: .9rem;"></i>
                                    <span>Búsqueda avanzada</span>
                                </button>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <input-search ref="input" v-model="form.item" @selected="handleProduct"
                                :source="{ url: `${baseUrl}/api/products`, method: 'POST' }"
                                :searchInput="false"
                                propLabel="des_prod" propValue="cdg_prod"
                                style="width: 100%;"
                            >
                                <template v-slot:selected="{ selected }">
                                    <div>
                                        <div class="d-flex flex-row">
                                            <span class="">{{ selected.cdg_prod }}</span>
                                            <span class="ms-2">{{ selected.des_prod }}</span>
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:default="{ item, index, select, isSelected }">
                                    <div :key="index" @click="select(item)" class="px-4 py-2 w-100 item-option" :class="{ 'selected': isSelected }">
                                        <div class="d-flex flex-row">
                                            <span class="fw-bold">Código</span>
                                            <span class="ms-2">{{ item.cdg_prod }}</span>
                                        </div>
                                        <div class="d-flex flex-row">
                                            <span class="fw-bold">Nombre</span>
                                            <span class="ms-2">{{ item.des_prod }}</span>
                                        </div>
                                        <div class="d-flex flex-row justify-content-between">
                                            <div class="d-flex flex-row">
                                                <span class="fw-bold">Marca</span>
                                                <span class="ms-2">{{ item.marca }}</span>
                                            </div>
                                            <!--<div class="d-flex flex-row">
                                                <span class="fw-bold">Modelo</span>
                                                <span class="ms-2">{{ item.modelo }}</span>
                                            </div>-->
                                        </div>
                                        <div class="d-flex flex-row justify-content-between">
                                            <div class="d-flex flex-row">
                                                <span class="fw-bold">Stock ({{ wareHouse.nombrealmacen }})</span>
                                                <span class="ms-2">{{ parseFloat(item.stock).toFixed(2) }}</span>
                                            </div>
                                            <div class="d-flex flex-row">
                                                <span class="fw-bold">Precio</span>
                                                <span class="ms-2">{{ item.monsig }} {{ parseFloat(item.preciov1).toFixed(3) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </input-search>
                            <button @click="handleSearch" class="btn btn-info ms-2" type="button">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-12 mb-3">
                        <label class="form-label required">Descripción del producto</label>
                        <textarea v-model="form.description" class="form-control" :required="true"></textarea>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-3">
                        <label class="form-label">Unidad Medida</label>
                        <select-async v-model="form.unit_measure"
                            :url="`${baseUrl}/api/tables?a=2&b=1&c=UNM&d=&e=1`"
                            propLabel="nombrecampo"
                            propValue="codigocampo"
                        ></select-async>
                    </div>
                    <div v-if="showPrice" class="col-sm-6 col-lg-4 col-xl-3 mb-3">
                        <label class="form-label">Lista de precios</label>
                        <dropdown>
                            <template v-slot:label>
                                <i class="fa fa-list"></i>
                                <span class="ms-2">Ver Precios</span>
                            </template>
                            <template v-slot:default="slotProps">
                                <ul class="list-group" style="min-width: 260px;">
                                    <li v-if="wareHouse.estadoprecio1 == '1'" class="list-group-item dropdown-item-between" :class="{ 'dropdown-hover': userSession.tipovalida !== '0' }" @click="handleSetPrice(form?.item?.preciov1, slotProps)">
                                        <span>{{ wareHouse.nombreprecio1 }}</span>
                                        <span class="text-end fw-bold">{{ form?.item?.preciov1 ?? 0  }}</span>
                                    </li>
                                    <li v-if="wareHouse.estadoprecio2 == '1'" class="list-group-item dropdown-item-between" :class="{ 'dropdown-hover': userSession.tipovalida !== '0' }" @click="handleSetPrice(form?.item?.preciov2, slotProps)">
                                        <span>{{ wareHouse.nombreprecio2 }}</span>
                                        <span class="text-end fw-bold">{{ form?.item?.preciov2 ?? 0  }}</span>
                                    </li>
                                    <li v-if="wareHouse.estadoprecio3 == '1'" class="list-group-item dropdown-item-between" :class="{ 'dropdown-hover': userSession.tipovalida !== '0' }" @click="handleSetPrice(form?.item?.preciov3, slotProps)">
                                        <span>{{ wareHouse.nombreprecio3 }}</span>
                                        <span class="text-end fw-bold">{{ form?.item?.preciov3 ?? 0  }}</span>
                                    </li>
                                    <li v-if="wareHouse.estadoprecio4 == '1'" class="list-group-item dropdown-item-between" :class="{ 'dropdown-hover': userSession.tipovalida !== '0' }" @click="handleSetPrice(form?.item?.preciov4, slotProps)">
                                        <span>{{ wareHouse.nombreprecio4 }}</span>
                                        <span class="text-end fw-bold">{{ form?.item?.preciov4 ?? 0  }}</span>
                                    </li>
                                </ul>
                            </template>
                        </dropdown>
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-3">
                        <label class="form-label">Factor de conversión</label>
                        <input v-model="form.factor" :readonly="true" class="form-control" type="text">
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-3">
                        <label class="form-label required">Total conversión</label>
                        <input v-model="form.quantity_conversion" type="text" :readonly="true" class="form-control">
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-3">
                        <label class="form-label required">Cantidad</label>
                        <input v-model="form.quantity" type="number" @input="handleQuantity" class="form-control">
                    </div>
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-3">
                        <label class="form-label required">
                            <span>Precio</span>
                            <span class="ms-2">({{  currency.nomabrevia }})</span>
                        </label>
                        <input v-model="form.price" :readonly="readOnlyPrice" type="number" @input="handlePrice" class="form-control">
                    </div>
                    <!--<div class="col-sm-6 col-lg-4 col-xl-3 mb-3">
                        <label class="form-label required">Descuento (%)</label>
                        <input v-model="form.discount_percentage" type="number" :readonly="true" class="form-control">
                    </div>-->
                    <div class="col-sm-6 col-lg-4 col-xl-3 mb-3">
                        <label class="form-label required">Total</label>
                        <input v-model="form.total" type="number" :readonly="true" class="form-control">
                    </div>
                    <div class="col-sm-12">
                        <label class="form-label required">Comentario</label>
                        <textarea v-model="form.comment" class="form-control"></textarea>
                    </div>
                </div>
            </template>

            <template #footer>
                <button @click="$emit('update:show', false)" type="button" class="btn btn-default">
                    <span>Cerrar</span>
                </button>
                <button @click="handleAccepted" type="button" class="btn btn-secondary-gradien">
                    <i class="fa fa-check"></i>
                    <span>Aceptar</span>
                </button>
            </template>
        </common-modal>

        <search-products
            :show.sync="showAdvance"
            @accepted="handleProduct"
            @selected="handleProduct"
        />

        <modal-stock :show.sync="showStock" :product="form.item" />
    </div>
</template>

<script>
import Dropdown from './common/Dropdown.vue';
import ModalStock from './ModalStock.vue';
export default {
    name: 'modal-product',
    components: {
        ModalStock,
        Dropdown,
    },
    data: () => ({
        showAdvance: false,
        showStock: false,
        monsig: null,
        form: {},
        readOnlyPrice: false,
    }),
    inject: ['baseUrl', 'wareHouse', 'userSession'],
    computed: {
        showPrice() {
            return this.userSession.mostrarprecio === '1'
        }
    },
    watch: {
        show(s) {
            if(s) {
                if(this.record == null) {
                    this.initForm();
                } else {
                    this.monsig = this.record.item.monsig
                    this.form = this.record
                }
            }
        }
    },
    props: {
        show: {
            type: Boolean,
        },
        record: {
            type: Object,
        },
        currency: {
            type: Object,
            default: () => ({
                codigocampo: "001",
                nombrecampo: "SOLES",
                nomabrevia: "S/"
            })
        },
        paymentType: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        initForm() {
            this.form = {
                id: 0,
                index: null,
                item: null,
                alert: null,
                description: null,
                price: 0,
                total: 0,
                factor: 1,
                discount_percentage: 0,
                quantity_conversion: 0,
                weight: 0,
                quantity: 0,
                unit_measure: '019',
                unit_measure_sigla: 'UND',
                quotization: {
                    label: null,
                    value: null,
                },
            }
        },
        handleAccepted() {
            this.calTotal()

            this.$emit('accepted', this.form);
            this.$emit('update:show', false);
        },
        handleProduct(item) {
            if(item) {
                this.monsig = item.monsig
                this.form.item = item
                this.form.code = item.cdg_prod;
                this.form.description = item.des_prod;
                this.form.unit_measure = item.cdg_umed;
                this.form.unit_measure_sigla = item.siglaum;
                this.form.quantity = 1;
                this.form.weight = item.pesoprod;

                this.readOnlyPrice = this.userSession.controlprecioventa === '0';
                let valordos = parseFloat(this.paymentType.valordos);
                
                if(this.userSession.tipovalida === '1') {
                    if(valordos === 0) {
                        this.form.price = item.preciov1;
                    } else {
                        if(this.userSession.posicionprecioventa === '2') {
                            this.form.price = item.preciov2;
                        } else if(this.userSession.posicionprecioventa === '3') {
                            this.form.price = item.preciov3;
                        } else {
                            this.form.price = item.preciov4;
                        }
                    }
                } else {
                    if(this.userSession.posicionprecioventa === '1') {
                        this.form.price = item.preciov1;
                    } else if(this.userSession.posicionprecioventa === '2') {
                        this.form.price = item.preciov2;
                    } else if(this.userSession.posicionprecioventa === '3') {
                        this.form.price = item.preciov3;
                    } else {
                        this.form.price = item.preciov4;
                    }
                }

                if(this.form.price === 0) {
                    this.readOnlyPrice = false;
                }

                this.calTotal()
            }
        },
        calTotal() {
            this.form.price = parseFloat(this.form.price);
            this.form.quantity = parseFloat(this.form.quantity);
            this.form.factor = 1;
            this.form.quantity_conversion = (this.form.factor * this.form.quantity);
            this.form.total = (this.form.price * this.form.quantity).toFixed(3);
        },
        handleQuantity(e) {
            this.calTotal()
        },
        handlePrice(e) {
            this.calTotal()
        },
        handleSearch(e) {
            this.$refs['input'].enterSearch(e);
        },
        handleSetPrice(price, slot) {
            if(this.userSession.tipovalida !== '1') {
                this.form.price = (price === undefined || price === null) ? 0 : price;
                this.calTotal()
                slot.toggle()
            }
        }
    }
}
</script>

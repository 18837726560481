<template>
    <div ref="modal" class="modal fade">
        <div v-if="show" class="bg-backdrop"></div>
        <div class="modal-dialog" :class="{ [modalSize]: true, }" role="document">
            <component ref="content" :is="alias" class="modal-content">
                <div class="modal-header">
                    <slot name="header">
                        <h6 class="modal-title">{{ title }}</h6>
                    </slot>
                    <button @click="$emit('close')" class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" data-bs-original-title="Cerrar" title="Cerrar"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div v-if="$scopedSlots['footer']" class="modal-footer d-flex justify-content-between">
                    <slot name="footer"></slot>
                </div>
            </component>
        </div>
    </div>
</template>

<style scoped>
.bg-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #222222;
    opacity: 0.5;
    width: 100vw;
    height: 100vh;
    transition: opacity 0.15s linear;
}
</style>

<script>
const bootstrap = require('bootstrap');

export default {
    name: 'common-modal',
    data: () => ({
        modalInstance: null,
    }),
    props: {
        show: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
        },
        size: {
            type: String,
            required: false,
            default: 'xs',
        },
        alias: {
            type: String,
            default: 'div',
        }
    },
    watch: {
        show(val) {
            if(val) {
                this.modalInstance?.show()
            } else {
                this.modalInstance?.hide();
            }
        }
    },
    computed: {
        modalSize() {
            if(this.size) {
                return `modal-${this.size}`;
            }
            return 'modal-xs';
        }
    },
    mounted() {
        this.$nextTick(() => {
            if(this.$refs['modal'])
            {
                this.modalInstance = new bootstrap.Modal(this.$refs['modal'], {
                    keyboard: false,
                    backdrop: false,
                });
            }
        })
    }
}
</script>

<template>
    <div class="position-relative dt-table table-responsive mt-3">
        <overlay-loader :loading="loading" />
        <table :id="tableId"
            class="table table-bordered dt-table">
            <thead class="table-light">
                <slot name="columns"></slot>
            </thead>
            <tbody>
                <template>
                    <template v-for="(row, index) in records">
                        <slot :row="row" :index="index">
                            <tr :key="index"></tr>
                        </slot>
                    </template>
                </template>
            </tbody>
            <slot name="tfoot"></slot>
        </table>
    </div>
</template>

<script>
import { isArray } from 'lodash';
export default {
    name: 'dt-table',
    props: {
        remote: {
            type: [String, Object],
            required: false,
            default: null,
        },
        nroCols: {
            type: [Number, String],
        },
        config: {
            type: Object,
            required: false,
            default: () => ({})
        },
        items: {
            type: Array,
            required: false,
            default: null,
        },
    },
    data: () => ({
        tableId: null,
        loading: false,
        records: [],
        init: false,
    }),
    computed: {
        axiosConfig() {
            if(typeof this.remote === 'string') {
                return {
                    url: this.remote,
                    method: 'POST',
                }
            } else {
                return this.remote;
            }
        },
    },
    watch: {
        items: {
            deep: true,
            handler(_current) {
                if(isArray(_current))
                {
                    this.records = _current;

                    this.refresh();
                }
            }
        }
    },
    methods: {
        fetchData(config = {}) {
            this.destroyDataTable()

            return new Promise((resolve, reject) => {
                this.loading = true;
                this.$http({
                    ...this.axiosConfig,
                    ...config,
                }).then((response) => {
                    this.records = response.data;
                    resolve(response.data);
                }).catch(e => {
                    reject(e);
                }).finally(() => {
                    this.loading = false;

                    this.initDataTable();
                });
            });
        },
        refresh() {
            if(this.remote) {
                this.fetchData().then((data) => {
                    this.records = data;
                })
            }

            if(isArray(this.items)) {
                //console.dir(this.items)
                //this.destroyDataTable()
                //this.initDataTable()
            }
        },
        initDataTable() {
            this.$nextTick(() => {
                if($.fn.DataTable) {
                    let dtConfig = $.extend($.fn.dataTable.defaults, this.config, { destroy: true, });
                    $(`#${this.tableId}`).DataTable(dtConfig);
                    this.init = true
                }
            })
        },
        destroyDataTable() {
            this.$nextTick(() => {
                if(this.init) {
                    $(`#${this.tableId}`).DataTable().destroy()
                    this.init = false
                }
            })
        }
    },
    mounted() {
        this.tableId = this.$randomUUID('dt-Table__')
        this.refresh();
    }
}
</script>

<style lang="css" scoped>
.dt-table tr {
    background-color: #ffffff;
}
.row-selected {
    cursor: pointer;
}
.row-selected.active {
    background-color: #b4c7ff !important;
}
</style>

<template>
    <div class="card">
        <table-products class="card-body"></table-products>
    </div>
</template>

<script>
export default {
    name: 'orders-consult-products',
}
</script>

<template>
    <common-modal title="Buscar productos" size="xl" :show="show" @close="$emit('update:show', false)">

        <template #header>
            <h6 class="modal-title">
                <i class="fa fa-search"></i>
                <span class="ms-2">Buscar producto</span>
            </h6>
        </template>

        <template>
            <table-products
                @accepted="handleAccepted"
                @selected="handleAccepted"
            ></table-products>
        </template>

        <template #footer>
            <button @click="$emit('update:show', false)" class="btn btn-default">
                <span>Cerrar</span>
            </button>
            <button @click="clickAccepted" class="btn btn-secondary-gradien">
                <i class="fa fa-check"></i>
                <span>Aceptar</span>
            </button>
        </template>
    </common-modal>
</template>

<script>
export default {
    name: 'search-products',
    data: () => ({
        selectedItem: null,
    }),
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleAccepted({ row }) {
            this.selectedItem = row
            this.$emit('accepted', this.selectedItem)
            this.$emit('update:show', false)
        },
        clickAccepted() {
            this.$emit('accepted', this.selectedItem)
            this.$emit('update:show', false)
        }
    }
}
</script>

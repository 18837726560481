<template>
    <div class="container-fluid">
        <div class="row widget-grid">
            <div class="col-12">
                <div class="mb-3">
                    <div class="d-flex justify-content-between">
                        <button @click="isOpen = !isOpen" type="button" class="btn text-start px-2 d-flex align-items-center" style="width: 80%;" data-bs-toggle="collapse" data-bs-target="#search-box">
                            <i v-if="isOpen" class="fa fa-minus"></i>
                            <i v-else class="fa fa-plus"></i>
                            <span class="text-uppercase ms-2">Filtros de búsqueda</span>
                        </button>
                    </div>
                    <div class="mt-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-3 col-lg-2 col-form-label text-sm-end mb-3">
                                        <label class="d-flex flex-row align-items-center">
                                            <span class="me-2">Rango de fechas</span>
                                            <input v-model="search.has_date" type="checkbox" class="form-check-input" />
                                        </label>
                                    </div>
                                    <div class="col-sm-9 col-lg-8 col-xl-6 mb-3">
                                        <div class="d-flex flex-row align-items-center gap-3">
                                            <div class="input-group">
                                                <span class="input-group-text">Desde</span>
                                                <input v-model="search.start_date" type="text"
                                                    class="datepicker-here form-control digits" data-language="es"
                                                    :disabled="!search.has_date"
                                                />
                                            </div>
                                            <div class="input-group">
                                                <span class="input-group-text">Hasta</span>
                                                <input v-model="search.end_date" type="text"
                                                    class="datepicker-here form-control digits" data-language="es"
                                                    :disabled="!search.has_date"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row collapse" id="search-box">
                                    <div class="col-6 col-lg-4">
                                        <div class="row mb-3">
                                            <div class="col-12 col-lg-6 col-form-label text-sm-end">
                                                <label class="d-flex flex-row align-items-center">
                                                    <span class="me-2">Estado seguimiento</span>
                                                    <input v-model="search.has_number" type="checkbox" class="form-check-input" />
                                                </label>
                                            </div>
                                            <div class="col-12 col-lg-6">
                                                <input-search
                                                    :source="{ url: `${resource}/tables?table=status`, method: 'POST' }"
                                                    v-model="search.status"
                                                    prop-value="codigocampo" prop-label="nombrecampo"
                                                    :init-load="true" :inputSearch="false"
                                                    :disabled="!search.has_number"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-lg-4">
                                        <div class="row mb-3">
                                            <div class="col-12 col-lg-6 col-form-label text-sm-end mb-2">Número seguimiento</div>
                                            <div class="col-12 col-lg-6">
                                                <input v-model="search.number_tracking" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col text-end">
                                        <button @click="asyncData" class="btn btn-info-gradien">
                                            <i class="fa fa-search"></i>
                                            <span>Buscar</span>
                                        </button>
                                        <a :href="exportUrl" class="btn btn-success-gradien">
                                            <i class="fa fa-file-excel-o"></i>
                                            <span>Exportar</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <dt-table
                            ref="table">
                            <template #columns>
                                <tr>
                                    <th class="text-uppercase" style="width: 30px !important;">-</th>
                                    <th class="text-uppercase">Estado</th>
                                    <th class="text-uppercase">Número Segui.</th>
                                    <th class="text-uppercase">Fecha Seguimiento</th>
                                    <th class="text-uppercase">Cliente</th>
                                    <th class="text-uppercase">Tipo de cliente</th>
                                    <th class="text-uppercase">Número de cliente</th>
                                    <th class="text-uppercase">Fecha de comprob.</th>
                                    <th class="text-uppercase">Detalle</th>
                                    <th class="text-uppercase">Comentario</th>
                                </tr>
                            </template>

                            <template slot-scope="{ row, index }">
                                <tr class="row-selected"
                                    :class="{'active': selectedIndex === index}"
                                    @click="handleSelectedRow(row, index)">
                                    <td>
                                        <div>
                                            <a href="javascript: void(0);" data-bs-toggle="dropdown" class="btn btn-primary btn-sm btn-pill">
                                                <i class="fa fa-ellipsis-v"></i>
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="#">
                                                    <i class="icon-list"></i>
                                                    <span class="ms-2">...</span>
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ row.nombreestado }}</td>
                                    <td>{{ row.numero_segui }}</td>
                                    <td>{{ row.fechasegui }}</td>
                                    <td>{{ row.nombrecliente }}</td>
                                    <td>{{ row.tipodoc }}</td>
                                    <td>{{ row.numerocomp }}</td>
                                    <td>{{ row.fechacompro }}</td>
                                    <td>{{ row.textodetalle }}</td>
                                    <td>{{ row.textocomentario }}</td>
                                </tr>
                            </template>
                        </dt-table>
                    </div>
                </div>
            </div>
        </div>
        <tracking-events ref="events" v-show="selectedRow.id !== 0" :params="selectedRow"></tracking-events>
    </div>
</template>

<script>
export default {
    name: 'orders-index',
    data: () => ({
        isOpen: false,
        search: {
            start_date: null,
            end_date: null,
            status: null,
            number_tracking: '',
            has_date: true,
            has_number: false,
        },
        selectedIndex: -1,
        selectedRow: {
            id: 0,
            numero: '',
        },
    }),
    inject: ['baseUrl'],
    props: {
        resource: {
            type: String,
            required: false,
        }
    },
    computed: {
        exportUrl() {
            return `${this.resource}/export?`
        }
    },
    methods: {
        asyncData() {
            this.$refs['table'].fetchData({
                url: `${this.resource}/records`,
                method: 'POST',
                data: this.search,
            })
        },
        handleSelectedRow(row, index) {
            this.selectedIndex = index
            this.selectedRow = {
                index: index,
                id: row.id,
                numero: row.numero_segui,
            }
            this.$refs['events'].refresh()
        },
    },
    mounted() {
        this.search.end_date = moment().format('DD/MM/YYYY');
        this.search.start_date = moment().add(-2, 'month').format('DD/MM/YYYY');

        setTimeout(() => {
            this.asyncData()
        }, 900)
    }
}
</script>

<template>
    <div class="row widget-grid">
        <div class="col-12">
            <div class="card">
                <div class="card-header d-flex align-items-center justify-content-between">
                    <div>
                        <h5 class="text-uppercase mb-0">Lista de seguimiento de: {{ params.numero }}</h5>
                    </div>
                    <div>
                        <button @click="newEvent" type="button" class="btn btn-secondary-gradien">
                            <i class="icon-plus"></i>
                            <span>Agregar evento</span>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <dt-table ref="tbEvents" v-show="renderTable" :remote="{ url: `${baseUrl}/orders/tracking/events?id=${params.id}`, method: 'POST' }">
                        <template slot="columns">
                            <tr>
                                <th class="text-uppercase">Nro. Item</th>
                                <th class="text-uppercase">Estado</th>
                                <th class="text-uppercase">Nro. Registro</th>
                                <th class="text-uppercase">Fecha Evento</th>
                                <th class="text-uppercase">Usuario que registra</th>
                                <th class="text-uppercase">Personal de contacto</th>
                                <th class="text-uppercase">Fecha referencia</th>
                                <th class="text-uppercase">Comentario</th>
                            </tr>
                        </template>
                        <template slot-scope="{ row, index }">
                            <tr :data-index="index" @click="handleClikRow(row)">
                                <td>{{ row.numero_sec }}</td>
                                <td>{{ row.nombreestado }}</td>
                                <td>{{ row.numero }}</td>
                                <td>{{ row.fechaevento }}</td>
                                <td>{{ row.usuario_registra }}</td>
                                <td>{{ row.personacontacto }}</td>
                                <td>{{ row.fechareferencia }}</td>
                                <td>{{ row.glosatexto }}</td>
                            </tr>
                        </template>
                    </dt-table>
                </div>
            </div>
        </div>
        <event-form ref="form" :show.sync="showForm" :tracking="params" @success="handleSuccess"></event-form>
    </div>
</template>
<script>
import EventForm from './EventForm.vue';
export default {
    name: 'tracking-events',
    components: {
        EventForm,
    },
    inject: ['baseUrl'],
    data: () => ({
        showForm: false,
        renderTable: false,
    }),
    props: {
        params: {
            type: Object,
            default: () => ({
                id: 0,
                numero: '',
            }),
        }
    },
    methods: {
        newEvent() {
            this.showForm = true
        },
        handleClikRow(row) {
            this.$refs['form'].setReadOnly(row)
        },
        handleSuccess() {
            this.$refs['tbEvents'].refresh()
        },
        refresh() {
            this.renderTable = false
            this.$nextTick(() => {
                this.$refs['tbEvents'].refresh()
                this.renderTable = true
            })
        }
    },
}
</script>

<template>
    <div class="position-relative">
        <slot name="trigger" @toggle="handleToggle">
            <button @click="handleToggle" type="button" class="btn btn-primary text-white"><slot name="label">{{ label }}</slot></button>
        </slot>
        <div v-show="isOpen" class="position-absolute">
            <div class="mt-1 position-relative" style="z-index: 1;">
                <slot :toggle="handleToggle" :isOpen="isOpen"></slot>
            </div>
        </div>
        <div v-if="isOpen" class="position-fixed top-0" style="width: 100%; height: 100%; left: 0;" @click="isOpen = false"></div>
    </div>
</template>

<script>
export default {
    name: 'dropdown',
    data: () => ({
        isOpen: false,
    }),
    props: {
        label: {
            type: String,
            required: false,
            default: 'Toggle',
        }
    },
    methods: {
        handleToggle() {
            this.isOpen = !this.isOpen;
        }
    }
}
</script>

<style>
.dropdown-item-hover {
    cursor: pointer;
}

.dropdown-item-hover:hover {
    background-color: #ECECEC;
}

.dropdown-item-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>

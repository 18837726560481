<template>
    <div v-if="loading" class="position-absolute d-flex align-items-center justify-content-center w-100 h-100" style="background-color: #ffffff9c; z-index: 40;">
        <div class="fs-5 p-2">
            <img src="/images/ajax-loader.gif" />
            <span v-if="showText">Cargando...</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'overlay-loader',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        showText: {
            type: Boolean,
            required: false,
            default: true,
        }
    }
}
</script>

<template>
    <div class="container-fluid">
        <div class="row widget-grid">
            <div class="col-12">
                <form ref="form" class="card needs-validation" method="POST" novalidate>
                    <div class="card-body">
                        <ul class="nav nav-tabs border-tab nav-primary" id="info-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="info-home-tab" data-bs-toggle="tab" href="#info-home" role="tab" aria-controls="info-home" aria-selected="true">
                                    <i class="icofont icofont-info"></i>
                                    <span class="text-uppercase">Datos generales</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a :class="{ 'nav-link': true, 'disabled': tabEnableDetail }" id="profile-info-tab" data-bs-toggle="tab" href="#info-detail" role="tab" aria-controls="info-profile" aria-selected="false">
                                    <i class="icofont icofont-list"></i>
                                    <span class="text-uppercase">Detalle de productos</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content position-relative">
                            <overlay-loader :loading="loading" />
                            <div class="tab-pane fade show active" id="info-home" aria-labelledby="info-home-tab">
                                <div class="row">
                                    <div v-if="!isNew" class="col-md-4 col-xl-2 position-relative mb-3">
                                        <label class="form-label required">N° Pedido</label>
                                        <input v-model="form.number_order" class="form-control" type="text" required>
                                        <div class="invalid-feedback text-danger">Se requiere número de pedido.</div>
                                    </div>
                                    <div class="col-md-4 col-xl-2 position-relative mb-3">
                                        <label class="form-label required">Fecha Pedido</label>
                                        <input v-model="form.order_date" class="form-control" type="date" required="">
                                        <div class="invalid-feedback text-danger">Se requiere ingrese fecha de pedido.</div>
                                    </div>
                                    <div class="col-md-4 col-xl-2 position-relative mb-3">
                                        <label class="form-label required">Fecha Recepción</label>
                                        <input v-model="form.reception_date" class="form-control" type="date" required>
                                        <div class="invalid-feedback text-danger">Se requiere ingrese fecha de recepción.</div>
                                    </div>
                                    <div class="col-md-4 col-xl-2 position-relative mb-3">
                                        <label class="form-label required">Hora Recepción</label>
                                        <input v-model="form.reception_time" class="form-control" type="time">
                                        <div class="invalid-feedback text-danger">Se requiere ingrese hora de recepción.</div>
                                    </div>
                                    <div class="col-md-4 col-xl-2 position-relative mb-3">
                                        <label class="form-label required">Fecha Entrega</label>
                                        <input v-model="form.deliver_date" class="form-control" type="date" required>
                                        <div class="invalid-feedback text-danger">Se requiere ingrese fecha de entrega.</div>
                                    </div>
                                    <div v-if="form.author_code" class="col-md-4 col-xl-2 position-relative mb-3">
                                        <label class="form-label required">Usuario registra</label>
                                        <input v-model="form.author_code" type="text" class="form-control" readonly="readonly">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-lg-12 mb-3">
                                        <label class="form-label required" for="cliente">Cliente</label>
                                        <input-search v-model="form.customer" ref="input_customer"
                                            prop-value="ruccliente" prop-label="nombrecliente"
                                            :source="{ url: `${baseUrl}/api/clients`, method: 'POST' }"
                                            @selected="selectedCustomer" required id="cliente"
                                            type="text">
                                            <template v-slot:selected="{selected}">
                                                <span class="me-2">{{ selected.ruccliente }}</span>
                                                <span>{{ selected.nombrecliente }}</span>
                                            </template>

                                            <template v-slot:default="{ item, isSelected, select }">
                                                <div @click="select(item)" class="px-4 py-2 w-100 d-flex flex-row cursor-pointer" :class="{ 'selected': isSelected }">
                                                    <span class="me-2">{{ item.ruccliente }}</span>
                                                    <span>{{ item.nombrecliente }}</span>
                                                </div>
                                            </template>
                                            <template #validation>
                                                <div class="invalid-feedback text-danger">Se requiere que seleccione cliente.</div>
                                            </template>
                                        </input-search>
                                    </div>
                                    <div class="col-sm-12 mb-3">
                                        <label class="form-label required" for="address-delivery">Dirección de entrega</label>
                                        <div class="input-group">
                                            <input v-model="form.address" type="text" class="form-control" id="address-delivery" required>
                                            <div class="invalid-feedback text-danger">Se requiere dirección de entrega.</div>
                                            <button @click="openChangeAddress" type="button" class="btn btn-info">
                                                <i class="fa fa-async"></i>
                                                <span>Cambiar dirección</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                                        <label class="form-label required" for="nro-order">Orden de compra</label>
                                        <input v-model="form.purchase_order" type="text" id="nro-order" name="nro-order" class="form-control">
                                    </div>
                                    <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                                        <label class="form-label required" for="currency_code">Moneda</label>
                                        <select-async
                                            id="currency_code" required
                                            v-model="form.currency_code"
                                            :url="`${baseUrl}/api/tables?c=MON`"
                                            prop-label="nombrecampo"
                                            prop-value="codigocampo"
                                            @change="changeCurrency"
                                        ></select-async>
                                        <div class="invalid-feedback">Se requiere que seleccione moneda.</div>
                                    </div>
                                    <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                                        <label class="form-label required">Forma de pago</label>
                                        <select-async v-model="form.payment_type"
                                            :url="`${baseUrl}/api/tables?a=2&b=1&c=CPG&d=004&e=2`"
                                            prop-label="nombrecampo"
                                            prop-value="codigocampo"
                                            :disabled="items.length > 0"
                                            @change="changePayment"
                                        ></select-async>
                                    </div>
                                    <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                                        <label class="form-label required">Forma de pago (Ingresar)</label>
                                        <input v-model="form.payment_type_text" type="text" class="form-control">
                                    </div>
                                    <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                                        <label class="form-label required">Vendedor</label>
                                        <input-search v-model="form.seller"
                                            prop-value="codigocampo" prop-label="nombrecampo"
                                            :minLength="0"
                                            :source="{ url: `${baseUrl}/api/tables?a=3&e=0`, method: 'POST' }"
                                            type="text"
                                            :disabled="!changeSeller"
                                            ref="seller"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-4 col-xl-3 mb-3">
                                        <label class="form-label required">Forma de despacho</label>
                                        <select-async v-model="form.dispatch_type"
                                            :url="`${baseUrl}/api/tables?a=2&b=1&c=FDE&d=004&e=1`"
                                            prop-label="nombrecampo"
                                            prop-value="codigocampo"
                                        ></select-async>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="info-detail" aria-labelledby="info-detail-tab">
                                <dt-table ref="details" :nroCols="15" :items="items">
                                    <template #columns>
                                        <tr>
                                                <th class="text-uppercase">Item</th>
                                                <!--<th class="text-uppercase">Tipo producto</th>-->
                                                <th class="text-uppercase">código producto</th>
                                                <th class="text-uppercase w-25">Nombre de producto</th>
                                                <th class="text-uppercase">U. Medida</th>
                                                <th class="text-uppercase">Alerta</th>
                                                <!--<th class="text-uppercase">Factor U. medida</th>
                                                <th class="text-uppercase">Conversión cantidad</th>-->
                                                <th class="text-uppercase">Cantidad</th>
                                                <th class="text-uppercase">Precio Unitario</th>
                                                <!--<th class="text-uppercase">Desc. %</th>-->
                                                <th class="text-uppercase">Total importe</th>
                                                <!--<th class="text-uppercase">Peso Prod.</th>
                                                <th class="text-uppercase">Cotización</th>
                                                <th class="text-uppercase">Comentario</th>-->
                                            </tr>
                                    </template>

                                    <template v-slot:default="{ row, index }">
                                        <tr :key="index" class="row-selected"
                                            :class="{ active: selectedRow.index == index }"
                                            @click="handleSelectedRow({ row, index })"
                                        >
                                            <td>{{ row.index }}</td>
                                            <!--<td>{{ row.type }}</td>-->
                                            <td>{{ row.code }}</td>
                                            <td class="w-25">{{ row.description }}</td>
                                            <td>{{ row.unit_measure_sigla }}</td>
                                            <td>{{ row.alert }}</td>
                                            <!--<td>{{ row.factor }}</td>
                                            <td>{{ row.quantity_conversion }}</td>-->
                                            <td>{{ row.quantity }}</td>
                                            <td>{{ row.price }}</td>
                                            <!--<td>{{ row.discount_percentage }}</td>-->
                                            <td>{{ row.total }}</td>
                                            <!--<td>{{ row.weight }}</td>
                                            <td>{{ row.quotization.label }}</td>
                                            <td>{{ row.comment }}</td>-->
                                        </tr>
                                    </template>
                                </dt-table>
                                <div class="row">
                                    <div class="col-sm-12 my-3">
                                        <button @click="handleAdd" type="button" class="btn btn-success-gradien btn-sm">
                                            <i class="fa fa-plus"></i>
                                            <span>Agregar</span>
                                        </button>
                                        <button @click="handleEdit" :disabled="selectedRow.index === -1" type="button" class="btn btn-info-gradien btn-sm">
                                            <i class="fa fa-edit"></i>
                                            <span>Editar</span>
                                        </button>
                                        <button @click="handleDestroy" :disabled="selectedRow.index === -1" type="button" class="btn btn-danger-gradien btn-sm">
                                            <i class="fa fa-trash"></i>
                                            <span>Eliminar</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-lg-2 col-form-label mb-3">Comentario</div>
                            <div class="col-sm-12 col-lg-10 mb-3">
                                <textarea v-model="form.comment" name="commentario" id="commentario" cols="30" rows="3" class="form-control"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-lg-4 offset-lg-8">
                                <div class="row mb-3">
                                    <div class="col-sm-4 col-form-label">Sub Total</div>
                                    <div class="col-sm-8">
                                        <input :value="$currencyFormat(form.subtotal)" :readonly="true" type="text" class="form-control text-end">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-4 col-form-label">% Descuento</div>
                                    <div class="col-sm-8">
                                        <input :value="$currencyFormat(form.porc_descto)" :readonly="true" type="text" class="form-control text-end">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-4 col-form-label">I.G.V (18%)</div>
                                    <div class="col-sm-8">
                                        <input :value="$currencyFormat(form.total_igv)" :readonly="true" type="text" class="form-control text-end">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-sm-4 col-form-label">Total</div>
                                    <div class="col-sm-8">
                                        <input :value="$currencyFormat(form.total)" :readonly="true" type="text" class="form-control text-end">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body d-flex justify-content-between">
                        <a :href="`${resource}`" type="button" class="btn btn-lg btn-default">
                            <i class="fa fa-dismiss"></i>
                            <span>Cancelar</span>
                        </a>
                        <button @click="handleSubmit" type="button" class="btn btn-lg btn-primary" :disabled="loading">
                            <i class="fa fa-save"></i>
                            <span>Guardar</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <modal-address
            v-if="form.customer"
            :show.sync="showModalAddress"
            @accepted="acceptedAddress"
            :ruc="form.customer.ruccliente"
        />

        <modal-product
            :show.sync="showModalProduct"
            @accepted="acceptedItem"
            :record="selectedRow.row"
            :currency="currency"
            :paymentType="selectPaymentType"
        />
    </div>
</template>

<script>
import { findIndex } from 'lodash';
import moment from 'moment';

export default {
    name: 'orders-form',
    data: () => ({
        loading: false,
        showModalAddress: false,
        showModalProduct: false,
        selectedRow: {
            index: -1,
            row: null,
        },
        form: {
            customer: { ruccliente: null },
            seller: null,
            payment_type: null,
        },
        items: [],
        currency: {
            codigocampo: "001",
            nombrecampo: "SOLES",
            nomabrevia: "S/"
        },
        selectPaymentType: null,
    }),
    inject: ['baseUrl', 'userSession'],
    computed: {
        isNew() {
            return this.id === '0';
        },
        tabEnableDetail() {
            return this.userSession.tipovalida === '1' && (this.form.payment_type === null || this.form.payment_type === '')
        }
    },
    props: {
        resource: {
            type: String,
            required: true,
        },
        id: {
            type: [String, Number],
            required: false,
        },
        changeSeller: {
            type: Boolean,
            required: false,
            default: true,
        },
        currentSeller: {
            type: Object,
            required: false,
            default: () => {
                return {
                    codigocampo: '',
                    nombrecampo: '',
                }
            }
        },
    },
    methods: {
        initializeForm() {
            this.$refs['seller'].httpSearch('')

            this.form = {
                index: 1,
                order_date: moment().format('YYYY-MM-DD'),
                reception_date: moment().format('YYYY-MM-DD'),
                deliver_date: moment().format('YYYY-MM-DD'),
                reception_time: moment().format('hh:mm'),
                currency_code: '001',
                payment_type: null,
                payment_type_text: null,
                dispatch_type: null,
                purchase_order: null,
                address: null,
                customer: null,
                comment: '',
                unit_measure: null,
                unit_measure_sigla: null,
                seller: this.currentSeller,
                author_code: null,
                comment: '',
                alert: null,
                weight: 0,
                discount_percentage:0,
                subtotal: 0,
                total: 0,
                total_igv: 0,
                total_descto: 0,
                porc_igv: 18,
                porc_descto: 0,
            }

            this.loadData()
        },
        loadData() {
            if(this.id && this.id !== '0' && this.id !== 0) {
                this.asyncData(this.id)
            }
        },
        asyncData(id) {
            this.loading = true
            this.$http.post(`${this.resource}/${id}`)
                .then(({ data }) => {
                    this.form = data
                    this.items = data.items;
                    this.selectPaymentType = data.selected_payment_type;
                }).finally(() => {
                    this.loading = false
                })
        },
        selectedCustomer(customer) {
            this.form.direccioncliente = customer.direccioncliente
            this.form.lugarentrega = customer.direccioncliente
            this.form.address = customer.direccioncliente
        },
        handleSubmit(e) {
            if(this.$refs['form'].checkValidity()) {
                this.loading = true;
                this.$http.post(`${this.resource}`, {
                    ...this.form,
                    items: this.items,
                }).then(({ data }) => {
                    $.notify(data.message, {
                        type: data.success ? 'success' : 'danger',
                        allow_dismiss: true,
                        delay: 2000,
                        timer: 300,
                        animate:{
                            enter:'animated fadeInDown',
                            exit:'animated fadeOutUp'
                        }
                    });

                    if(data.success) {
                        //this.asyncData(data.data.id);
                        setTimeout(() => {
                            window.location = `${this.resource}`
                        }, 1000)
                    }
                }).finally(() => {
                    this.loading = false;
                })
            }
            this.$refs['form'].classList.add('was-validated');
        },
        acceptedItem(item) {
            if(item.index === null) {
                const index = findIndex(this.items, { code: item.code })
                if(index == -1) {
                    item.index = this.items.length + 1;
                    this.items.push(item);
                } else {
                    swal({
                        title: "¿Desea duplicar el producto?",
                        text: "Este producto ya ha sido agregado",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                        buttonConfirmText: 'Duplicar',
                        buttonCancelText: 'Cancelar'
                    })
                    .then((willDelete) => {
                        if(willDelete) {
                            item.index = this.items.length + 1;
                            this.items.push(item);
                        }
                    });
                }
            } else {
                this.items[item.index - 1] = item
            }

            this.selectedRow = {
                index: -1,
                row: null,
            }

            this.calTotal()
        },
        changePayment(item) {
            this.selectPaymentType = item
            if(item) {
                this.form.payment_type_text = item.nombrecampo
            } else {
                this.form.payment_type_text = ''
            }
        },
        handleSelectedRow({ row, index }) {
            this.selectedRow = {
                row,
                index
            }
        },
        handleAdd() {
            this.showModalProduct = true;
            this.selectedRow = {
                index: -1,
                row: null,
            }
        },
        handleEdit() {
            this.showModalProduct = true
        },
        handleDestroy() {
            let item = this.items[this.selectedRow.index]
            swal({
                title: "¿Desea eliminar el registro?",
                text: "Esta acción no se puede deshacer!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                buttonConfirmText: 'Sí',
                buttonCancelText: 'No'
            })
            .then((willDelete) => {
                if(willDelete) {
                    this.items.splice(this.selectedRow.index, 1)
                    this.$lodash.each(this.items, (item, index) => {
                        this.items[index] = {
                            ...item,
                            index,
                        }
                    })

                    if(item.id !== 0) {
                        this.$http.delete(`${this.resource}/destroyItem`, {
                            params: {...item, ruc: this.form.customer.ruccliente},
                        }).then(({ data }) => {
                            $.notify(data.message, {
                                type: data.success ? 'success' : 'danger',
                                allow_dismiss: true,
                                delay: 2000,
                                timer: 300,
                                animate:{
                                    enter:'animated fadeInDown',
                                    exit:'animated fadeOutUp'
                                }
                            });
                        });
                    }

                    this.calTotal()
                }
            })
        },
        calTotal() {
            let subtotal = 0
            this.$lodash.map(this.items, (item) => {
                subtotal += parseFloat(item.total)
            })
            this.form.subtotal = subtotal.toFixed(2)
            this.form.total_igv = (subtotal * (this.form.porc_igv / 100.00)).toFixed(2)
            this.form.total = parseFloat(this.form.subtotal + this.form.total_igv).toFixed(2)
        },
        openChangeAddress() {
            if(this.form.customer === null)
            {
                $.notify('Se requiere que seleccione cliente.', {
                    type: 'danger',
                    allow_dismiss: true,
                    delay: 2000,
                    timer: 300,
                    animate:{
                        enter:'animated fadeInDown',
                        exit:'animated fadeOutUp'
                    }
                });
                return;
            }
            this.showModalAddress = true;
        },
        acceptedAddress(info) {
            this.form.lugarentrega = info.direccioncliente;
            this.form.address = info.direccioncliente;
        },
        changeCurrency(v) {
            this.currency = v
        }
    },
    mounted() {
        this.initializeForm()

        if(this.$refs['input_customer']) {
            this.$refs['input_customer'].$refs['search'].focus()
        }
    }
}
</script>

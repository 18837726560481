<template>
    <div class="position-relative">
        <div v-if="showResults" class="position-fixed w-100 h-100 top-0 left-0" @click="toggleOverlay"></div>
        <div class="position-relative">
            <input v-if="searchBox && !readonly"
                v-model="search" autocomplete="off"
                ref="search" class="form-control"
                @focus="focusInput"
                @input="inputSearch"
                @keypress.enter="enterSearch"
                :disabled="disabled"
                :id="id" v-bind="$attrs" />
            <div v-else @click="toggleSearch" :class="{
                'form-control w-100 d-flex flex-row justify-content-center selected-option': true,
                'bg-light bg-gradient text-body': (disabled || readonly),
            }">
                <div class="text-truncate" style="width: 100%;">
                    <slot name="selected" :selected="selected">
                        <span class="w-100 cursor-pointer">{{ $lodash.get(selected, propLabel) }}</span>
                    </slot>
                </div>
                <div v-if="!disabled && !readonly" class="px-2" @click="handleSelected(null)">
                    <i class="fa fa-close"></i>
                </div>
            </div>
            <slot name="validation"></slot>
            <div class="position-absolute w-100">
                <div v-show="showResults && !readonly" class="position-relative bg-white mt-2 border rounded results">
                    <overlay-loader :loading="loading" :show-text="false" />
                    <template v-for="(item, index) in items">
                        <slot :item="item"
                            :select="handleSelected"
                            :isSelected="selected !== null && $lodash.get(selected, propValue) === $lodash.get(item, propValue)"
                        >
                            <div :key="index" @click="handleSelected(item)" class="px-4 py-2 w-100 cursor-pointer">{{ $lodash.get(item, propLabel) }}</div>
                        </slot>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.cursor-pointer {
    cursor: pointer;
}
.left-0 {
    left: 0;
}
.results {
    overflow-y: auto;
    min-height: 40px;
    max-height: 200px;
    z-index: 20;
}
.selected-option {
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    min-height: 37.6px;
    cursor: pointer !important;
}
.selected {
    background: #e7e7e7;
}
.item-option {
    cursor: pointer !important;

    &:hover {
        background: #e7e7e7;
    }
}
</style>

<script>
export default {
    name: 'input-search',
    data: () => ({
        searchBox: true,
        showResults: false,
        selected: null,
        loading: false,
        items: [],
        search: '',
    }),
    props: {
        source: {
            type: [Object, Array],
            required: true,
        },
        propValue: {
            type: String,
            required: true,
            default: 'value',
        },
        propLabel: {
            type: String,
            required: true,
            default: 'value',
        },
        minLength: {
            type: Number,
            required: false,
            default: 2,
        },
        value: {
            type: Object,
            required: false,
        },
        id: {
            type: String,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        searchInput: {
            type: Boolean,
            required: false,
            default: true,
        },
        initLoad: {
            type: Boolean,
            required: false,
            defaultValue: false,
        }
    },
    watch: {
        value: {
            deep: true,
            handler(v) {
                this.searchBox = (v == null || v == undefined);
                this.selected = v

                if(this.items.length == 0 && v) {
                    this.items.push(v)
                }
            }
        }
    },
    methods: {
        inputSearch(e) {
            if(this.searchInput) {
                let value = this.search
                if(value.length >= this.minLength) {
                    this.showResults = true;
                    if(this.$lodash.isArray(this.source)) {
                        this.items = this.source.filter((x) => {
                            return this.$lodash.get(x, this.propLabel).toLowerCase().includes(value.toLowerCase());
                        });
                    } else {
                        this.httpSearch(value)
                    }
                }
            }
        },
        enterSearch() {
            let value = this.search
            this.showResults = true;
            this.httpSearch(value)
        },
        httpSearch(value) {
            let dataParam = this.$lodash.merge({}, this.source.data || {}, { query: value, })
            this.loading = true;
            this.$http({
                ...this.source,
                data: dataParam,
            }).then(response => {
                this.items = response.data;
            }).finally(() => {
                this.loading = false;
            });
        },
        handleSelected(item) {
            this.showResults = false
            this.selected = item;
            this.searchBox = false;

            this.$emit('selected', item)
            this.$emit('input', this.selected)
            this.search = ''
        },
        toggleSearch() {
            if(!this.disabled && !this.readonly) {
                this.searchBox = !this.searchBox
                this.showResults = true

                this.$nextTick(() => {
                    this.$refs['search'].focus();
                })
            }
        },
        toggleOverlay() {
            this.showResults = !this.showResults

            if(this.selected != null) {
                this.searchBox = false
            }
        },
        focusInput() {
            if(this.items.length > 0 && this.showResults === false && !this.readonly) {
                this.showResults = true
            }
        }
    },
    mounted() {
        if(this.initLoad) {
            this.httpSearch('')
        }
    }
}
</script>

<template>
    <div class="card">
        <div class="card-header pb-0 card-no-border">
            <h3 class="card-text">Últimas ordenes</h3>
        </div>
        <div class="card-body">
            <dt-table :remote="{ url: `${resource}/records`, data: {last: true}, method: 'POST' }" style="margin-top: -1.3rem !important;">
                <template #columns>
                    <tr>
                        <th class="text-uppercase">N° Pedido</th>
                        <th class="text-uppercase">Fecha Pedido</th>
                        <th class="text-uppercase" style="min-width: 250px;">Cliente</th>
                        <th class="text-uppercase">Ord. compra</th>
                        <th class="text-uppercase">Estado</th>
                        <th class="text-uppercase">Vendedor</th>
                        <th class="text-uppercase">Moneda</th>
                        <th class="text-uppercase">Total</th>
                        <th class="text-uppercase">Destino</th>
                    </tr>
                </template>
                <template v-slot:default="{row, index}">
                    <tr :key="index">
                        <td>{{ row.numpedido }}</td>
                        <td>{{ row.fechaemision }}</td>
                        <td>
                            <div class="d-flex flex-column">
                                <small class="fw-bold">RUC:</small>
                                <span>{{ row.ruccliente }}</span>
                                <small class="fw-bold text-uppercase">Razón social:</small>
                                <span>{{ row.nombrecliente }}</span>
                            </div>
                        </td>
                        <td>{{ row.nordencompra }}</td>
                        <td>{{ row.estado }}</td>
                        <td>{{ row.sigla_vendedor }}</td>
                        <td class="text-end">{{ row.tipomone }}</td>
                        <td class="text-end">{{ row.totalpedido }}</td>
                        <td>{{ row.destino }}</td>
                    </tr>
                </template>
            </dt-table>
        </div>
        <div class="card-body d-flex align-items-center justify-content-center pt-3">
            <a :href="resource">Ver todos</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'last-orders',
    props: {
        resource: {
            type: String,
            required: true,
        }
    }
}
</script>

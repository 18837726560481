<template>
    <select class="form-control" @change="changeOption">
        <slot>
            <option :value="null">-- SELECCIONE --</option>
        </slot>
        <template v-for="(item, index) in items">
            <slot name="option" :isSelected="$lodash.get(item, propValue) === value" :item="item" :index="index">
                <option
                    :value="$lodash.get(item, propValue)"
                    :key="index"
                    :selected="$lodash.get(item, propValue) === value"
                    :class="{
                        selected: $lodash.get(item, propValue) === value,
                    }"
                >{{ $lodash.get(item, propLabel) }}</option>
            </slot>
        </template>
    </select>
</template>

<script>
export default {
    name: 'select-async',
    data: () => ({
        items: [],
    }),
    props: {
        url: {
            type: String,
            required: true,
        },
        method: {
            type: String,
            required: false,
            default: 'POST',
        },
        firstDefault: {
            type: Boolean,
            default: false,
        },
        propLabel: {
            type: String,
        },
        propValue: {
            type: String,
        },
        value: {
            type: [String, Number],
        }
    },
    methods: {
        fetchData() {
            return new Promise((resolve, reject) => {
                this.$http({
                    url: this.url,
                    method: this.method,
                }).then(response => {
                    this.items = response.data;
                    resolve(response.data);
                }).catch(e => {
                    reject(e)
                })
            })
        },
        changeOption(e) {
            const { value } = e.target
            this.$emit('input', value);
            this.$emit('change', this.$lodash.find(this.items, { [this.propValue]:value }), e);
        }
    },
    mounted() {
        this.fetchData().then(() => {
            if(this.value) {
                this.$emit('input', this.value)
            } else {
                if(this.firstDefault) {
                    this.$emit('input', this.$lodash.get(this.$lodash.head(this.items), this.propValue))
                }
            }
        });
    }
}
</script>

<style scoped>
.selected {
    background: #e7e7e7;
}
</style>

<template>
    <div class="row widget-grid">
        <div class="col-12 col-lg-7">
            <div class="card">
                <div class="card-header">
                    <h5>Dominios</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <label for="name" class="form-label">Nombre</label>
                            <input v-model="form.name" id="name" autocomplete="off" name="name" class="form-control" />
                        </div>
                        <div class="col-12 mb-3">
                            <label for="name" class="form-label">Dominios</label>
                            <div v-for="(item, index) in form.hostnames" :key="index" class="d-flex flex-row mb-2">
                                <input v-model="form.hostnames[index].fqdn" placeholder="empresa.midominio.com" class="form-control" />
                                <button @click="removeDomainClick(index, $event)" type="button" class="btn btn-danger ms-2">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                            <button @click="addDomainClick" type="button" class="btn btn-link">
                                <i class="fa fa-plus"></i>
                                <span>Agregar</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-end">
                    <button @click="handleSubmit" type="button" class="btn btn-primary">
                        <i class="fa fa-save"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-5">
            <div class="card">
                <div class="card-header">
                    <h5>Conexión</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-9 mb-3">
                            <label for="host" class="form-label">Servidor</label>
                            <input v-model="form.connection.data.host" id="host" name="host" class="form-control" />
                        </div>
                        <div class="col-3 mb-3">
                            <label for="port" class="form-label">Puerto</label>
                            <input v-model="form.connection.data.port" id="port" name="port" class="form-control" />
                        </div>
                        <div class="col-12 mb-3">
                            <label for="database" class="form-label">Base de datos</label>
                            <input v-model="form.connection.data.database" id="database" name="database" class="form-control" />
                        </div>
                        <div class="col-12 mb-3">
                            <label for="username" class="form-label">Usuario</label>
                            <input v-model="form.connection.data.username" id="username" name="username" class="form-control" />
                        </div>
                        <div class="col-12 mb-3">
                            <label for="password" class="form-label">Contraseña</label>
                            <input v-model="form.connection.data.password" id="password" name="password" class="form-control" />
                        </div>
                        <div class="col-12 mb-3">
                            <label for="driver" class="form-label">Driver</label>
                            <select v-model="form.connection.data.driver" class="form-control">
                                <option value="mysql">MySQL/Maria DB</option>
                                <option value="sqlsrv">SQL Server</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="card-footer d-flex justify-content-end">
                    <button @click="handleSubmit" type="button" class="btn btn-primary">
                        <i class="fa fa-save"></i>
                        <span>Guardar</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "sites-form",
    data: () => ({
        form: {
            id: 0,
            uuid: null,
            name: '',
            hostnames: [],
            connection: {
                name: '',
                data: {
                    host: '127.0.0.1',
                    port: '1433',
                    driver: 'sqlsrv',
                    engine: null,
                    prefix: '',
                    strict: true,
                    charset: 'utf8mb4',
                    collation: 'utf8mb4_unicode_ci',
                    unix_socket: '',
                    username: 'sa',
                    database: 'master',
                    password: '',
                }
            }
        },
    }),
    props: {
        id: {
            type: Number|String,
            required: false,
            default: null,
        }
    },
    computed: {
        isNew() {
            return this.id === null
        }
    },
    methods: {
        addDomainClick() {
            this.form.hostnames.push({
                id: 0,
                fqdn: '',
            })
        },
        removeDomainClick(index) {
            let hostname = this.form.hostnames[index]
            this.form.hostnames.splice(index, 1)

            if(hostname.id !== 0) {
                this.$http.delete(`/sites/remove/domain/${hostname.id}`).then(({ data }) => {
                    $.notify(data.message, {
                        type: data.success ? 'success' : 'danger',
                        allow_dismiss: true,
                        delay: 2000,
                        timer: 300,
                        animate:{
                            enter:'animated fadeInDown',
                            exit:'animated fadeOutUp'
                        }
                    });
                })
            }
        },
        handleSubmit() {
            this.$http.post(`/sites`, this.form).then(({ data }) => {
                $.notify(data.message, {
                    type: data.success ? 'success' : 'danger',
                    allow_dismiss: true,
                    delay: 2000,
                    timer: 300,
                    animate:{
                        enter:'animated fadeInDown',
                        exit:'animated fadeOutUp'
                    }
                });

                if(data.success) {
                    window.location.href = '/sites'
                }
            })
        }
    },
    mounted() {
        if(!this.isNew) {
            this.$http.post(`/sites/${this.id}/show`).then(response => {
                if(response.status === 200) {
                    this.form = response.data
                }
            })
        }
    }
}
</script>

<template>
    <common-modal
        :show="show" @close="handleClose"
        title="Registro de evento"
    >
        <div class="row">
            <div class="col-6 mb-3">
                <label class="form-label required">Fecha de evento</label>
                <input v-model="form.event_date" :readonly="readOnlyForm" class="form-control" type="date" required>
                <div class="invalid-feedback text-danger">Se requiere ingrese fecha de evento.</div>
            </div>
            <div class="col-6 mb-3">
                <label class="form-label required">Estado del evento</label>
                <input-search
                    :source="{ url: `${baseUrl}/orders/tracking/tables?table=status_evt`, method: 'POST' }"
                    v-model="form.status" :searchInput="false"
                    prop-value="codigocampo" prop-label="nombrecampo"
                    :init-load="true" :readonly="readOnlyForm"
                />
            </div>
            <div v-if="form.status.codigocampo === '002'" class="col-12 mb-3">
                <label class="form-label">Frase</label>
                <input v-model="form.phrase" :readonly="readOnlyForm" type="text" class="form-control">
            </div>
            <div class="col-12 mb-3">
                <label class="form-label">Con la persona que se hablo</label>
                <input v-model="form.person_contact" :readonly="readOnlyForm" type="text" class="form-control">
            </div>
            <div class="col-6 mb-3">
                <label class="form-label">Teléfono de contacto</label>
                <input v-model="form.contact_phone" :readonly="readOnlyForm" maxlength="9" type="text" class="form-control">
            </div>
            <div class="col-6 mb-3">
                <label class="form-label">Tipo de comunicación</label>
                <input v-model="form.communication_type" :readonly="readOnlyForm" type="text" class="form-control">
            </div>
            <div class="col-12 mb-3">
                <label class="form-label">Que es lo que se conversó</label>
                <textarea v-model="form.discussed" :readonly="readOnlyForm" class="form-control"></textarea>
            </div>
            <div class="col-6 mb-3">
                <label class="form-label">Fecha proxima a contactar</label>
                <input v-model="form.next_date" :readonly="readOnlyForm" type="date" class="form-control">
            </div>
        </div>

        <template slot="footer">
            <template v-if="readOnlyForm">
                <button @click="handleClose" type="button" class="btn btn-default">Ocultar</button>
            </template>
            <template v-else>
                <button @click="handleClose" type="button" class="btn btn-default">Cancelar</button>
                <button @click="handleSubmit" type="button" class="btn btn-secondary-gradien">
                    <i class="fa fa-save"></i>
                    <span>Guardar</span>
                </button>
            </template>
        </template>
    </common-modal>
</template>

<script>
export default {
    name: 'event-form',
    inject: ['baseUrl', 'wareHouse'],
    data: () => ({
        readOnlyForm: false,
        form: {
            event_date: null,
            status: {
                codigocampo: '001',
                nombrecampo: 'PENDIENTE'
            },
            phrase: '',
            person_contact: '',
            contact_phone: '',
            communication_type: '',
            discussed: '',
            check_next_date: false,
            next_date: null,
        }
    }),
    props: {
        show: {
            type: Boolean,
        },
        tracking: {
            type: Object,
        },
    },
    methods: {
        castDate(d) {
            if(typeof d === 'string') {
                let parts = d.substring(0, 10).split('/')
                return `${parts[2]}-${parts[1]}-${parts[0]}`
            }
            return null
        },
        setReadOnly(form) {
            console.dir(form)
            this.form = {
                status: {
                    codigocampo: '',
                    nombrecampo: form.nombreestado,
                },
                person_contact: form.personacontacto,
                discussed: form.glosatexto,
                event_date: this.castDate(form.fechaevento),
                next_date: this.castDate(form.fechareferencia),
            }
            this.readOnlyForm = true
            this.$emit('update:show', true)
        },
        handleClose() {
            this.readOnlyForm = false
            this.$emit('update:show', false)
        },
        initForm() {
            this.form = {
                id: 0,
                tracking_id: this.tracking.id,
                event_date: null,
                status: {
                    codigocampo: '001',
                    nombrecampo: 'PENDIENTE'
                },
                phrase: '',
                person_contact: '',
                contact_phone: '',
                communication_type: null,
                discussed: '',
                next_date: null,
            }
        },
        handleSubmit() {
            this.$http.put(`${this.baseUrl}/orders/tracking/events`, {
                ...this.form,
                tracking_id: this.tracking.id,
            })
                .then(({ data }) => {
                    $.notify(data.message, {
                        type: data.success ? 'success' : 'danger',
                        allow_dismiss: true,
                        delay: 2000,
                        timer: 300,
                        animate:{
                            enter:'animated fadeInDown',
                            exit:'animated fadeOutUp'
                        }
                    });

                    if(data.success) {
                        this.handleClose()

                        this.$emit('success', {
                            ...this.form,
                            tracking_id: this.tracking.id,
                        })
                    }
                })
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initForm()
        })
    }
}
</script>
